import React, { useMemo, createContext, FC, useContext, useEffect, useState, useCallback } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'

import { getUserSessionGroup, noop, noopAsync, getErrorMessage } from 'helpers'
import analytics, { AnalyticsEvents } from 'helpers/analytics'
import { getNewToken } from 'helpers/getNewToken'
import { JwtTokenName } from 'shared/constants'
import { AppDispatch } from 'store'
import { messageActions } from 'store/slices/message'
import { userActions } from 'store/slices/user'
import { ROUTES } from 'types/routes'
import { EUserGroup, TCustomUserAttributes, TSignUpData, TUser, TUserAttributes } from 'types/user.types'

import { TAuthContext } from './auth.types'
import { usePreloader } from '../preloader'

async function getUser(): Promise<CognitoUser | void> {
    try {
        const userData = await Auth.currentAuthenticatedUser()
        return userData
    } catch (err) {
        return console.log('Not signed in', err)
    }
}

const AuthContext = createContext<TAuthContext>({
    userAuth: null,
    isAuth: false,
    userGroup: EUserGroup.EMPLOYEE,
    signIn: noop,
    signOut: noopAsync,
    signUp: noop,
    completeNewPassword: async () => {
        return
    },
    updateUserAttribute: noop,
    forgotUserPassword: noop,
    forgotUserPasswordSubmit: async () => {
        return
    },
    refreshJWTToken: noopAsync,
})

AuthContext.displayName = 'AuthContext'

const defaultAuthData = { isAuth: false, loading: false, user: null as null | TUser, group: EUserGroup.EMPLOYEE }

//todo: remake all without re-render (code is Г)!!!
export const AuthProvider: FC<{ dispatch: AppDispatch; children: React.ReactNode }> = ({ children, dispatch }) => {
    const navigate = useNavigate()

    const [authData, setAuthData] = useState<typeof defaultAuthData>({ ...defaultAuthData, loading: true })
    const [userAttributes, setUserAttributes] = useState<TUserAttributes | null>(null)

    const { showPreloader } = usePreloader()
    const { shutdown } = useIntercom()

    const setUserData = async (userData: CognitoUser) => {
        const companyId = userData.attributes['custom:companyId']
        const employeeId = userData.attributes['custom:employeeId']

        if (companyId === undefined || employeeId === undefined) {
            await signOut()
            return
        }
        //remove this
        const currentUser: TUser = {
            firstName: userData.attributes.name,
            lastName: userData.attributes.family_name,
            email: userData.attributes.email,
            id: userData.username,
            companyId,
            employeeId,
        }

        const currentUserAttributes: TUserAttributes = {
            isCompanyCreated: userData.attributes['custom:isCompanyCreated'] === 'true',
            isProfileConfirmed: userData.attributes['custom:isProfileConfirmed'] === 'true',
        }

        setUserAttributes(currentUserAttributes)

        try {
            const currentSession = await Auth.currentSession()
            const tokenIdJwt = currentSession.getIdToken().getJwtToken()
            const group = await getUserSessionGroup(userData)

            localStorage.setItem(JwtTokenName, tokenIdJwt)

            setAuthData({
                isAuth: true,
                loading: false,
                user: currentUser,
                group: getGroup(group),
            })
        } catch (err) {
            setAuthData(defaultAuthData)
            dispatch(messageActions.messageShown({ text: getErrorMessage(err), severity: 'error' }))
        }
    }

    const signIn = async (email: string, password: string) => {
        showPreloader(true)
        try {
            const userData = await Auth.signIn(email, password)
            if (userData) {
                if (userData.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    dispatch(messageActions.messageShown({ text: 'Please, change your password', severity: 'error' }))
                } else {
                    await setUserData(userData)
                    analytics.logEvent(AnalyticsEvents.LOGIN)
                    dispatch(messageActions.messageShown({ text: 'Welcome!', severity: 'success' }))
                }
            }
        } catch (err) {
            dispatch(messageActions.messageShown({ text: getErrorMessage(err), severity: 'error' }))
        }
        showPreloader(false)
    }

    const signUp = async (data: TSignUpData) => {
        try {
            await Auth.signUp(data)
        } catch (err) {
            dispatch(messageActions.messageShown({ text: getErrorMessage(err), severity: 'error' }))
            throw err
        }
    }

    const signOut = async () => {
        try {
            await Auth.signOut({ global: true })
            shutdown()
            setAuthData(defaultAuthData)
            dispatch(userActions.userDataCleared())
            navigate(ROUTES.HOME)
            analytics.logEvent(AnalyticsEvents.LOGOUT)
        } catch (err) {
            dispatch(messageActions.messageShown({ text: getErrorMessage(err), severity: 'error' }))
        }
    }

    const forgotUserPassword = async (email: string) => {
        if (email.length === 0) {
            throw new Error('Email cannot be empty')
        }
        try {
            await Auth.forgotPassword(email)
            analytics.logEvent(AnalyticsEvents.PASSWORD_RESET)
        } catch (err) {
            const error = err as Error
            throw error
        }
    }

    const forgotUserPasswordSubmit = async (email: string, code: string, newPassword: string) => {
        console.log('forgotUserPasswordSubmit')
        try {
            await Auth.forgotPasswordSubmit(email, code, newPassword)
            await Auth.signOut({ global: true })
            await signIn(email, newPassword)
            navigate(ROUTES.HOME)
        } catch (err) {
            dispatch(messageActions.messageShown({ text: getErrorMessage(err), severity: 'error' }))
        }
    }

    const completeNewPassword = async (email: string, tempPassword: string, newPassword: string) => {
        let userData
        try {
            userData = await Auth.signIn(email, tempPassword)
        } catch (err) {
            dispatch(messageActions.messageShown({ text: 'Invitation link has expired', severity: 'error' }))
        }

        try {
            if (userData?.challengeName === 'NEW_PASSWORD_REQUIRED') {
                const confirmedUser = await Auth.completeNewPassword(userData, newPassword)
                if (confirmedUser) {
                    const user = await Auth.currentAuthenticatedUser()
                    await setUserData(user)
                }
            }
        } catch (err) {
            dispatch(messageActions.messageShown({ text: getErrorMessage(err), severity: 'error' }))
        }
    }

    const updateUserAttribute = async (
        attributeName: keyof TCustomUserAttributes,
        attributeValue: string,
        shortname: keyof TUserAttributes,
    ) => {
        try {
            const user = await Auth.currentAuthenticatedUser()

            if (user && userAttributes) {
                const data: Partial<TCustomUserAttributes> = {}
                data[attributeName] = attributeValue

                await Auth.updateUserAttributes(user, data)

                const newAttributes = {
                    ...userAttributes,
                    [shortname]: attributeValue === 'true',
                }
                setUserAttributes(newAttributes)
            }
        } catch (err) {
            dispatch(messageActions.messageShown({ text: getErrorMessage(err), severity: 'error' }))
        }
    }

    const refreshJWTToken = async () => {
        try {
            console.log('refresh token')
            const tokenIdJwt = await getNewToken()

            localStorage.setItem(JwtTokenName, tokenIdJwt)
        } catch (err) {
            dispatch(
                messageActions.messageShown({
                    text: 'Unable to refresh Token, ' + getErrorMessage(err),
                    severity: 'error',
                }),
            )
        }
    }

    const fetchData = async () => {
        try {
            const userData = await getUser()

            if (userData) {
                await setUserData(userData)
            } else {
                setAuthData(defaultAuthData)
            }
        } catch (err) {
            setAuthData(defaultAuthData)
            dispatch(messageActions.messageShown({ text: getErrorMessage(err), severity: 'error' }))
        }
    }

    useEffect(() => {
        ;(async () => {
            await fetchData()
        })()
    }, [])

    const contextValue = useMemo(
        () => ({
            userAuth: authData.user,
            isAuth: authData.isAuth,
            userGroup: authData.group,
            signIn,
            signUp,
            signOut,
            completeNewPassword,
            userAttributes,
            updateUserAttribute,
            refreshJWTToken,
            forgotUserPassword,
            forgotUserPasswordSubmit,
        }),
        [
            authData.user,
            authData.isAuth,
            authData.group,
            signIn,
            signUp,
            signOut,
            completeNewPassword,
            userAttributes,
            updateUserAttribute,
            refreshJWTToken,
            forgotUserPassword,
            forgotUserPasswordSubmit,
        ],
    )

    if (authData.loading) {
        return (
            <div className="position-center">
                <CircularProgress />
            </div>
        )
    }

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

function getGroup(group?: EUserGroup[] | null): EUserGroup {
    if (group?.includes(EUserGroup.MANAGER)) {
        return EUserGroup.MANAGER
    } else if (group?.includes(EUserGroup.TEAM_LEAD)) {
        return EUserGroup.TEAM_LEAD
    } else {
        return EUserGroup.EMPLOYEE
    }
}

const useAuth = (): TAuthContext => useContext(AuthContext)

useAuth.displayName = 'useAuth'

export { useAuth }
