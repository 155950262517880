import moment from 'moment'

import { MultiselectItem } from 'types/common.types'

import { EAvailabilityType, TEmployee, TReservable } from '../types/data.types'

export const noop = () => {
    return
}

export const noopAsync = async () => {
    return
}

export const search = (str: string, searchValue: string) => str.search(new RegExp(searchValue, 'i')) !== -1

export const getQueryParams = <T extends string>(queries: string, keys: Array<T>) => {
    const searchParams = new URLSearchParams(queries)

    return keys.reduce((acc, key) => {
        const currValue = searchParams.get(key)
        if (currValue) {
            acc[key] = currValue
        }
        return acc
    }, {} as { [key in T]: string })
}

export const getPluralizationString = (
    length: number,
    curr: string,
    settings?: { plural?: string; suffix?: string },
): string => {
    if (length === 1 || settings === undefined) {
        return curr
    }

    return settings.plural === undefined ? `${curr}${settings.suffix || ''}` : settings.plural
}

export const capitalizeString = ([first, ...rest]: string) => first.toLocaleUpperCase() + rest.join('')

export const getConvertedDate = (date: string | Date | moment.Moment, format?: string) =>
    moment(date).format(format || 'MMMM D')
export const getConvertedEngLocaleDate = (date: string | Date | moment.Moment, format?: string) =>
    moment(date)
        .locale('en')
        .format(format || 'MMMM D')

export const exhaustiveCheck = (_: never) => {
    return
}

export const getSelectItemKey = (item: MultiselectItem, key: 'name' | 'id' = 'name') =>
    typeof item === 'object' ? item[key] : item

export const getErrorMessage = (error: Error | { message: string } | string) =>
    typeof error !== 'string' ? error.message : error

/**
 * get timeline time from ISODate (HH:mm)
 * **/
export const getHoursFromISODateString = (date: string) => date.split('T')[1].substring(0, 5)
export const getAWSStringFromDateAndTime = (date: string, time: string) => `${date}T${time}:00.000Z`
export const getTimeInHours = (timeString: string): number => {
    const [hours, minutes] = timeString.split(':').map(Number)
    return hours + minutes / 60
}

export const getMinutesFromMidnight = (time: string) => {
    const mnt = moment(time, 'HH:mm')
    const mMidnight = moment('00:00', 'HH:mm')

    return mnt.diff(mMidnight, 'minutes')
}

export const getRangeMinutes = (timeFrom: string, timeTo: string) => {
    const mmntFrom = moment(timeFrom, 'HH:mm')
    const mmntTo = moment(timeTo, 'HH:mm')

    return mmntTo.diff(mmntFrom, 'minutes')
}

export const getStringByMinutes = (minutes: number) => {
    const mMidnight = moment('00:00', 'HH:mm')
    return mMidnight.add(minutes, 'minutes').format('HH:mm')
}

export const getHourStringFromNumber = (hour: number, withHalf = false) => {
    const hourString = `${hour}`.padStart(2, '0')
    const minuteString = withHalf ? '30' : '00'

    return `${hourString}:${minuteString}`
}

export const checkExistsFunction =
    <T extends any[]>(cb?: (...args: T) => void) =>
    (...args: T) => {
        if (typeof cb === 'function') {
            cb(...args)
        }
    }

export const getPartDayTimeString = (time1?: string, time2?: string) => {
    if (!time1 || !time2) {
        return ''
    }

    return `${getHoursFromISODateString(time1)} - ${getHoursFromISODateString(time2)}`
}

export const getCookie = (name: string) => {
    const cookieArr = document.cookie.split('; ')

    for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split('=')

        if (name === cookiePair[0]) {
            return decodeURIComponent(cookiePair[1])
        }
    }

    // Return null if the cookie wasn't found
    return null
}

export const findOverlap = (arr1: string[], arr2: string[]) => {
    const set2 = new Set(arr2)
    return arr1.filter((item) => set2.has(item))
}

export const checkRoomIsAvailableForEmployee = (
    room: Pick<TReservable, 'employeeID' | 'availabilityType'> & {
        ReservableToDepartments: { items: { Department?: { id: string } }[] }
    },
    employee: Pick<TEmployee, 'id' | 'departmentIDs'>,
) => {
    switch (room.availabilityType) {
        case EAvailabilityType.BOOKED_FOR_PERSON: {
            return room.employeeID === employee.id
        }
        case EAvailabilityType.BOOKED_FOR_TEAM: {
            return (
                findOverlap(
                    (room.ReservableToDepartments?.items || [])
                        .filter((it) => !!it.Department)
                        .map((it) => it.Department!.id!) || [],
                    employee.departmentIDs || [],
                ).length > 0
            )
        }
        case EAvailabilityType.UNAVALIABLE: {
            return false
        }
        default: {
            return true
        }
    }
}

export function arraysEqual(a: any[], b: any[]) {
    if (a.length !== b.length) {
        return false
    }
    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
            return false
        }
    }
    return true
}
