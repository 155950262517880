import { createAsyncThunk } from '@reduxjs/toolkit'

import { QUERY_GET_BOOKING_BY_ID, QUERY_SCHEDULE_SPACE_BY_ID_DATA_SUBSCRIBE } from 'containers/Calendar/graphql'
import { Reservable, type Booking } from 'graphql/autogenerate/schemas'
import { getErrorMessage } from 'helpers'
import { messageActions } from 'store/slices/message'

import type { FormValues } from '../../../widgets/CalendarInfoDialog/types'

export const fetchCurrentBookingById = createAsyncThunk<Booking, { bookingID: string }, { extra: any }>(
    'editBooking/fetchCurrentBookingById',
    async ({ bookingID }, thunkAPI) => {
        const { client } = thunkAPI.extra
        try {
            const response = await client.query({
                query: QUERY_GET_BOOKING_BY_ID,
                variables: {
                    bookingID,
                },
                fetchPolicy: 'no-cache',
            })
            return response.data.getBooking
        } catch (err) {
            thunkAPI.dispatch(messageActions.messageShown({ text: getErrorMessage(err), severity: 'error' }))
            return thunkAPI.rejectWithValue(getErrorMessage(err))
        }
    },
)

export const fetchReservableById = createAsyncThunk<Reservable, { reservableID: string; filter: any }, { extra: any }>(
    'editBooking/fetchReservableById',
    async ({ reservableID, filter }, thunkAPI) => {
        const { client } = thunkAPI.extra
        try {
            const response = await client.query({
                query: QUERY_SCHEDULE_SPACE_BY_ID_DATA_SUBSCRIBE,
                variables: {
                    id: reservableID,
                    ...filter,
                },
                fetchPolicy: 'no-cache',
            })
            return response.data.getReservable
        } catch (err) {
            thunkAPI.dispatch(messageActions.messageShown({ text: getErrorMessage(err), severity: 'error' }))
            return thunkAPI.rejectWithValue(getErrorMessage(err))
        }
    },
)
